import { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FaUserCircle } from 'react-icons/fa';

import { RootState } from 'store';

import { getProfileActionAsync } from 'actions/userActions';

import FlexRowWrapper from 'components/common/FlexRowWrapper';
import NavProfileElementDropdown from './NavProfileElementDropdown';

import { ReactComponent as CaretDown } from 'assets/icons/caret-down.svg';

const NavProfileElementWrapper = styled.div`
  position: relative;
  cursor: pointer;

  ${FlexRowWrapper} {
    width: auto;
    align-items: center;
    padding: 10px 15px;

    .profile-image {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      margin-right: 16px;
      color: ${({ theme }) => theme.textColors.secondary};
    }

    & > div {
      margin-right: 10px;

      .username,
      .crypto-address {
        min-width: 66px;
        max-width: 100px;
      }

      .username {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 12px;
        text-transform: capitalize;
        color: ${({ theme }) => theme.textColors.primary};
        margin-bottom: 4px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .crypto-address {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 12px;
        color: ${({ theme }) => theme.textColors.secondary};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .caret-down {
      color: ${({ theme }) => theme.textColors.primary};
    }
  }
`;

interface NavProfileElementProps {
  dropdownEnabled?: boolean;
  toggleTheme?: () => void;
}

const NavProfileElement = ({
  dropdownEnabled = true,
  toggleTheme = () => {},
}: NavProfileElementProps): JSX.Element | null => {
  const [showDropdown, setShowDropdown] = useState(false);

  const wallet = useSelector((state: RootState) => state.wallet);
  const user = useSelector((state: RootState) => state.user);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (wallet.isWalletConnected) {
      dispatch(getProfileActionAsync());
    }
  }, [wallet, dispatch]);

  useEffect(() => {
    if (!user.profile && user.error) {
      history.push('/profile/create');
    }
  }, [user, history]);

  return wallet.isWalletConnected ? (
    <>
      <NavProfileElementWrapper
        onClick={() =>
          dropdownEnabled ? setShowDropdown((prevState) => !prevState) : null
        }
      >
        <FlexRowWrapper>
          {Boolean(user?.profile?.userProfileImageUrl) ? (
            <img
              src={user?.profile?.userProfileImageUrl?.replace(
                'ipfs.io',
                'firdaos.mypinata.cloud'
              )}
              alt=""
              className="profile-image"
            />
          ) : (
            <FaUserCircle className="profile-image" />
          )}
          <div>
            <h4 className="username">
              {user?.profile?.username || wallet.accountAddress}
            </h4>
            <p className="crypto-address">
              {user?.profile?.userCryptoAddress || wallet.accountAddress}
            </p>
          </div>
          {dropdownEnabled ? <CaretDown className="caret-down" /> : null}
        </FlexRowWrapper>
      </NavProfileElementWrapper>
      {showDropdown && (
        <NavProfileElementDropdown
          user={user}
          toggleTheme={toggleTheme}
          onClose={() => setShowDropdown(false)}
        />
      )}
    </>
  ) : null;
};

export default NavProfileElement;
