import { ethers } from 'ethers';

import { ethersProvider } from 'utils/ethers/provider';
import config from 'config';
import AuctionEngine from 'artifacts/AuctionEngine.json';

const auctionEngineContract = () => {
  const provider = ethersProvider();
  const contract = new ethers.Contract(
    config.eth.auctionEngineAddress,
    AuctionEngine.abi,
    provider
  );
  return contract;
};

export default auctionEngineContract;
