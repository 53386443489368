import { useMemo } from 'react';
import styled from 'styled-components/macro';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import useHotAuctionsQuery from 'hooks/auction/useHotAuctionsQuery';

import SectionTitle from 'components/common/SectionTitle';
import FeaturedAuctionCard from './FeaturedAuctionCard';

import { acceptedCryptoInfo } from 'utils/acceptedCryptoInfo';

const FeaturedAuctionsWrapper = styled.section`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(265px, 1fr));
  grid-row-gap: 45px;
  position: relative;

  .slider-wrapper {
    width: 100%;
    height: auto;
    margin: 0 auto;
  }

  .slick-arrow.slick-prev,
  .slick-arrow.slick-next {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  .slick-arrow.slick-prev:before,
  .slick-arrow.slick-next:before {
    width: 30px;
    height: 30px;
    font-size: 30px;
    border-radius: 50%;
    color: black;
  }

  .slick-arrow.slick-next {
    position: absolute;
    right: -25px;
  }
`;

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return <div className={className} style={{ ...style }} onClick={onClick} />;
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return <div className={className} style={{ ...style }} onClick={onClick} />;
}

const FeaturedAuctions = (): JSX.Element => {
  const hotAuctionsQuery = useHotAuctionsQuery();

  const hotAuctions = useMemo(() => {
    if (hotAuctionsQuery.data?.data?.docs?.length) {
      return hotAuctionsQuery?.data?.data?.docs;
    } else {
      return null;
    }
  }, [hotAuctionsQuery.data?.data]);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    swipeToSlide: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    draggable: true,
    responsive: [
      {
        breakpoint: 1120,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <SectionTitle title="Featured" />
      <FeaturedAuctionsWrapper>
        <div className="slider-wrapper">
          <Slider {...settings}>
            {hotAuctions?.length
              ? hotAuctions.map((hotAuction: any, i: number) => (
                  <FeaturedAuctionCard
                    key={i}
                    auctionIndex={hotAuction?.auctionIndex}
                    assetName={hotAuction?.nftDetails?.assetName}
                    assetIpfsHash={hotAuction?.nftDetails?.assetIpfsHash}
                    assetMimetype={hotAuction?.nftDetails?.assetMimetype}
                    creatorName={hotAuction?.userAccountHandle}
                    creatorProfileImage={hotAuction?.userProfileImage?.replace(
                      'ipfs.io',
                      'firdaos.mypinata.cloud'
                    )}
                    reservePrice={hotAuction?.startPrice || 0}
                    cryptoTokenSymbol={
                      acceptedCryptoInfo[
                        `${hotAuction?.erc20TokenAddress?.toLowerCase()}`
                      ]?.symbol
                    }
                    startsAt={hotAuction?.startTime}
                    endsAt={hotAuction?.endsAt}
                  />
                ))
              : null}
          </Slider>
        </div>
      </FeaturedAuctionsWrapper>
    </>
  );
};

export default FeaturedAuctions;
