import { useState, useEffect, Fragment, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components/macro';
import { useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { QueryClient, QueryClientProvider } from 'react-query';

import 'react-toastify/dist/ReactToastify.css';
import 'react-responsive-modal/styles.css';

import { useDarkMode, Theme } from 'hooks/useDarkMode';
import {
  disconnectWallet,
  setWalletConnectedAsync,
} from 'actions/walletActions';

import GlobalStyle from 'components/common/globalStyles';
import themeObj from 'theme';

import Layout from 'components/layout/Layout';
import ConnectWalletModal from 'components/ConnectWalletModal';

// Eagerly loaded Container Components
import LandingPage from 'containers/LandingPage';
import ComingSoon from 'components/ComingSoon';
import SpinnerLoader from 'components/SpinnerLoader';

import { ethersProvider } from 'utils/ethers/provider';
import requestMetaMaskAccounts from 'utils/metamask/requestMetaMaskAccounts';
import IncorrectNetworkModal from 'components/IncorrectNetworkModal';
import config from 'config';

// Lazily loaded Container Components.
const ProfilePage = lazy(() => import('containers/ProfilePage'));
const AuctionPage = lazy(() => import('containers/AuctionPage'));
const CreateProfile = lazy(() => import('containers/CreateProfile'));
const CreateNFT = lazy(() => import('containers/CreateNFT'));
const CreateAuction = lazy(() => import('containers/CreateAuction'));
const UpdateProfile = lazy(() => import('containers/UpdateProfile'));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  const [showIncorrectNetworkModal, setShowIncorrectNetworkModal] =
    useState(false);

  const dispatch = useDispatch();
  const { theme, toggleTheme } = useDarkMode();

  useEffect(() => {
    setTimeout(async () => {
      if (typeof window.ethereum !== 'undefined') {
        if (
          localStorage.getItem('isWalletConnected') === 'true' &&
          (await requestMetaMaskAccounts())?.[0] ===
            localStorage.getItem('accountAddress')
        ) {
          dispatch(setWalletConnectedAsync());
        }
      }
    }, 500);
  }, [dispatch]);

  // Handle a change in the network (e.g. Ropsten vs Mainnet)
  // https://docs.ethers.io/v5/concepts/best-practices/
  useEffect(() => {
    if (typeof window.ethereum !== 'undefined') {
      const provider = ethersProvider();
      provider.on('network', (newNetwork, oldNetwork) => {
        if (config.eth.networkType === 'ropsten') {
          if (newNetwork.name === 'ropsten') {
            setShowIncorrectNetworkModal(false);
          } else {
            setShowIncorrectNetworkModal(true);
          }
        } else {
          if (newNetwork.name === 'homestead') {
            setShowIncorrectNetworkModal(false);
          } else {
            setShowIncorrectNetworkModal(true);
          }
        }
      });
    }
  }, []);

  // Handle MetaMask Account Switch.
  useEffect(() => {
    if (typeof window.ethereum !== 'undefined') {
      window.ethereum.on('accountsChanged', (accounts: string[]) => {
        if (accounts.length) {
          dispatch(setWalletConnectedAsync());
        } else {
          dispatch(disconnectWallet());
        }
      });
    }
  }, [dispatch]);

  return (
    <Fragment>
      <GlobalStyle />
      <ThemeProvider
        theme={theme === Theme.Light ? themeObj.light : themeObj.dark}
      >
        <Suspense fallback={<SpinnerLoader />}>
          <QueryClientProvider client={queryClient}>
            <Router>
              <Layout toggleTheme={toggleTheme}>
                <Switch>
                  <Route path="/" exact component={LandingPage} />
                  <Route
                    path="/profile/create"
                    exact
                    component={CreateProfile}
                  />
                  <Route
                    path="/profile/:handle/edit"
                    exact
                    component={UpdateProfile}
                  />
                  <Route
                    path="/profile/:handle"
                    exact
                    component={ProfilePage}
                  />
                  <Route
                    path="/auction/create/:tokenId"
                    exact
                    component={CreateAuction}
                  />
                  <Route
                    path="/auction/:auctionId"
                    exact
                    component={AuctionPage}
                  />
                  <Route path="/nft/create" exact component={CreateNFT} />
                  <Route path="*" component={ComingSoon} />
                </Switch>
              </Layout>
            </Router>
          </QueryClientProvider>
        </Suspense>
        <ConnectWalletModal />
        <IncorrectNetworkModal
          isOpen={showIncorrectNetworkModal}
          onClose={() => setShowIncorrectNetworkModal(false)}
        />
        <ToastContainer />
      </ThemeProvider>
    </Fragment>
  );
};

export default App;
