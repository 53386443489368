import { ethers } from 'ethers';

import { ethersSigner } from 'utils/ethers/signer';
import FdoErc20Token from 'artifacts/FDO_ERC20_Token.json';
import config from 'config';

const FdoErc20TokenContract = () => {
  const signer = ethersSigner();
  const contract = new ethers.Contract(
    config.eth.fdoErc20TokenAddress,
    FdoErc20Token.abi,
    signer
  );
  return contract;
};

export default FdoErc20TokenContract;
