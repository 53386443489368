import config from 'config';
import { RootState } from 'store';
import { showConnectWalletModal } from './walletActions';

export const FETCHING_PROFILE = 'FETCHING_PROFILE';
export const FETCHED_PROFILE = 'FETCHED_PROFILE';
export const SET_PROFILE = 'SET_PROFILE';
export const FETCHING_PROFILE_FAILED = 'FETCHING_PROFILE_FAILED';

export const setProfileAction = (payload) => ({
  type: SET_PROFILE,
  payload,
});

export const getProfileActionAsync = () => async (
  dispatch,
  getState: () => RootState
) => {
  try {
    const { isWalletConnected, accountAddress } = getState().wallet;

    if (!isWalletConnected) return dispatch(showConnectWalletModal());

    dispatch({
      type: FETCHING_PROFILE,
    });
    const response = await fetch(
      `${config.apiBaseUrl}/profile?userCryptoAddress=${accountAddress}`
    );
    const ok = response.ok;
    if (ok) {
      const result = await response.json();
      if (result) {
        return dispatch({
          type: SET_PROFILE,
          payload: result,
        });
      }
    }
    throw new Error('Failed to fetch');
  } catch (error) {
    console.log(error);
    dispatch({
      type: FETCHING_PROFILE_FAILED,
      payload: 'Failed to fetch Profile',
    });
  }
};
