import { useEffect, useState } from 'react';
import { Theme } from './useDarkMode';

export const useCurrentTheme = () => {
  const [theme, setTheme] = useState<Theme>(Theme.Light);

  useEffect(() => {
    const localTheme = window.localStorage.getItem('theme');
    localTheme && setTheme(localTheme as Theme);
  }, [setTheme]);

  return theme;
};
