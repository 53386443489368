import { Erc20TokenOption } from 'types/erc20-token-option';

import { acceptedCryptoInfo } from './acceptedCryptoInfo';

import EthIcon from 'assets/icons/eth-currency-icon.svg';
import FirdaosIcon from 'assets/icons/firdaos-currency-icon.svg';
import TstIcon from 'assets/icons/test-currency-icon.svg';

const iconMapping = {
  ETH: EthIcon,
  TST: TstIcon,
  FDO: FirdaosIcon,
};

const erc20TokenOptions: Erc20TokenOption[] = Object.entries(
  acceptedCryptoInfo
).map(([key, value]) => ({
  label: (
    <label style={{ display: 'flex', alignItems: 'center' }}>
      <img
        style={{ width: 18, height: 18, marginRight: 12 }}
        src={iconMapping[`${value.symbol}`]}
        alt=""
      />
      {value.symbol}
    </label>
  ),
  value: key,
}));

export default erc20TokenOptions;
