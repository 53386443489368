import { useInfiniteQuery } from 'react-query';

import { fetchAuctionsList } from 'apis/fetchAuctionsList';

const useAuctionsListQuery = ({ limit }: { limit: number }) => {
  const auctionsListQuery = useInfiniteQuery(
    'auctions-list',
    ({ pageParam = 1 }) => fetchAuctionsList({ page: pageParam, limit }),
    {
      getNextPageParam: (lastPage, pages) => lastPage.data.nextPage,
    }
  );

  return auctionsListQuery;
};

export default useAuctionsListQuery;
