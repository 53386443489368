import { useInfiniteQuery } from 'react-query';

import { fetchExpiredAuctionsList } from 'apis/fetchExpiredAuctionsList';

interface UseExpiredAuctionsListQuery {
  limit: number;
}

const useExpiredAuctionsListQuery = ({
  limit,
}: UseExpiredAuctionsListQuery) => {
  const expiredAuctionsListQuery = useInfiniteQuery(
    'expired-auctions-list',
    ({ pageParam = 1 }) => fetchExpiredAuctionsList({ page: pageParam, limit }),
    {
      getNextPageParam: (lastPage, pages) => lastPage.data.nextPage,
    }
  );

  return expiredAuctionsListQuery;
};

export default useExpiredAuctionsListQuery;
