import styled from 'styled-components/macro';
import { NavLink, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RiLogoutBoxRLine, RiPencilFill, RiUserFill } from 'react-icons/ri';
import { FaTelegramPlane } from 'react-icons/fa';
import { SiTwitter, SiInstagram } from 'react-icons/si';
import { AiFillPlusCircle } from 'react-icons/ai';

import { RootState } from 'store';
import { disconnectWallet } from 'actions/walletActions';

import FlexColumnWrapper from 'components/common/FlexColumnWrapper';
import FlexRowWrapper from 'components/common/FlexRowWrapper';
import NavConnectWallet from 'components/navbar/NavConnectWallet';
import NavProfileElement from 'components/navbar/NavProfileElement';
import NewThemeToggle from 'components/NewThemeToggle';

const NavSliderWrapper = styled.div<{ isOpen: boolean }>`
  background: ${({ theme }) => theme.backgroundColors.secondary};

  width: 300px;
  height: calc(100vh - 80px);
  transition: all 0.45s ease-in-out;

  position: fixed;
  top: 80px;
  bottom: 0;
  right: ${(props) => (props.isOpen ? '0px' : '-100vw')};
  z-index: 110;

  & > ${FlexColumnWrapper} {
    width: auto;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 767.99px) {
      margin: 0 auto;
    }
  }

  ul {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-flow: column wrap;

    margin: 0px 0;

    li {
      list-style: none;

      a {
        height: 50px;
        width: 200px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-decoration: none;

        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;

        text-transform: uppercase;
        color: ${({ theme }) => theme.textColors.secondary};

        & > svg {
          margin-right: 24px;
        }
      }

      a.active {
        color: ${({ theme }) => theme.textColors.primary};
      }
    }
  }

  .create-nft-link {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    color: ${({ theme }) => theme.accentColors.primary} !important;
    text-transform: uppercase;
    text-decoration: none;
  }

  .connect-wallet-nav-slider {
    margin: 0 auto;
  }

  .center {
    width: 100%;
    text-align: center;
  }

  .social-links-slider {
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
    margin-top: 32px;

    & > .svg-group {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    a {
      width: 24px;
      height: 24px;
      margin: 0;

      display: grid;
      place-items: center;
      margin-right: 20px;

      &:last-of-type {
        margin-right: 0px;
      }

      svg {
        width: 20px !important;
        height: 20px !important;
        margin: 0;
        color: ${({ theme }) => theme.accentColors.primary};
      }
    }
  }
`;

interface NavSliderProps {
  isOpen: boolean;
  onClose: () => void;
  toggleTheme: () => void;
}

const NavSlider = ({
  isOpen,
  onClose,
  toggleTheme,
}: NavSliderProps): JSX.Element => {
  const user = useSelector((state: RootState) => state.user);
  const { isWalletConnected } = useSelector((state: RootState) => state.wallet);
  const dispatch = useDispatch();

  return (
    <NavSliderWrapper isOpen={isOpen}>
      <FlexColumnWrapper>
        <div>
          <ul>
            <li>
              <NavLink exact to="/" onClick={onClose}>
                Home
              </NavLink>
            </li>
            <li>
              <a href="https://firdaos.com/creators/" onClick={onClose}>
                Creators
              </a>
            </li>
            <li>
              <a href="https://firdaos.com/#about" onClick={onClose}>
                About Us
              </a>
            </li>
            <li style={{ width: '100%' }}>
              <FlexRowWrapper className="social-links-slider">
                <div className="svg-group">
                  <a href="https://t.me/FirdaosOfficial">
                    <FaTelegramPlane />
                  </a>
                  <a href="https://twitter.com/FirdaosOfficial">
                    <SiTwitter />
                  </a>
                  <a href="https://www.instagram.com/sahilsays/">
                    <SiInstagram />
                  </a>
                </div>
                <NewThemeToggle toggleTheme={toggleTheme} />
              </FlexRowWrapper>
            </li>
          </ul>
          {Boolean(user?.profile) ? (
            <ul>
              <li
                onClick={(e) => {
                  e.stopPropagation();
                  onClose();
                }}
              >
                {isWalletConnected && (
                  <NavLink
                    className="create-nft-link center"
                    to="/nft/create"
                    exact
                  >
                    <AiFillPlusCircle />
                    Create NFT
                  </NavLink>
                )}
              </li>
            </ul>
          ) : null}
          <NavProfileElement dropdownEnabled={false} />
          {Boolean(user?.profile) ? (
            <ul>
              <li
                onClick={(e) => {
                  e.stopPropagation();
                  onClose();
                }}
              >
                <NavLink
                  className="center"
                  to={`/profile/${user?.profile?.userAccountHandle}`}
                  exact
                >
                  <RiUserFill />
                  My Profile
                </NavLink>
              </li>
              <li
                onClick={(e) => {
                  e.stopPropagation();
                  onClose();
                }}
              >
                <NavLink
                  className="center"
                  to={`/profile/${user?.profile?.userAccountHandle}/edit`}
                  exact
                >
                  <RiPencilFill />
                  Edit Profile
                </NavLink>
              </li>
            </ul>
          ) : null}
          {isWalletConnected ? (
            <ul>
              <li
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(disconnectWallet());
                  onClose();
                }}
              >
                <Link to={`/`} className="center">
                  <RiLogoutBoxRLine />
                  Logout
                </Link>
              </li>
            </ul>
          ) : null}
        </div>
      </FlexColumnWrapper>
      <NavConnectWallet
        className="connect-wallet-nav-slider"
        onClick={onClose}
      />
    </NavSliderWrapper>
  );
};

export default NavSlider;
