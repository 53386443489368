import styled from 'styled-components/macro';
import BigNumber from 'bignumber.js';

import { useCurrentTheme } from 'hooks/useCurrentTheme';

import FlexColumnWrapper from 'components/common/FlexColumnWrapper';
import FlexRowWrapper from 'components/common/FlexRowWrapper';
import AuctionConditionalCountdown from './AuctionConditionalCountdown';

BigNumber.set({ DECIMAL_PLACES: 3 });

const HighestBidCountdownComboWrapper = styled.div`
  width: 100%;
  min-height: 134px;

  @media screen and (max-width: 625px) {
    width: 100%;
  }

  & > ${FlexRowWrapper} {
    width: 100%;
    height: 100%;
    justify-content: space-between;
    padding: 16px 0;

    @media screen and (max-width: 767.99px) {
      flex-flow: row wrap;
      padding: 12px;
    }

    ${FlexColumnWrapper} {
      width: auto;
      justify-content: center;
      align-items: center;
    }
  }

  .highest-bid-wrapper {
    justify-content: flex-start;

    * {
      width: 100%;
    }

    p {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.21px;
      color: ${({ theme }) => theme.textColors.secondary};
      margin-bottom: 12px;
      text-transform: uppercase;

      @media screen and (max-width: 420.99px) {
        margin-top: 4px;
      }
    }

    h2 {
      text-transform: uppercase;
      font-family: Inter;
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
      letter-spacing: -0.21px;
      color: ${({ theme }) => theme.textColors.primary};
      margin-bottom: 4px;

      @media screen and (max-width: 767.99px) {
        font-size: 24px;
        line-height: 30px;
      }

      @media screen and (max-width: 420.99px) {
        font-size: 20px;
        line-height: 38px;
      }
    }

    h4 {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;

      letter-spacing: -0.21px;
      color: ${({ theme }) => theme.textColors.tertiary};
      text-transform: uppercase;
    }
  }

  .countdown-wrapper {
    h2 {
      text-transform: uppercase;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 33px;
      line-height: 40px;
      letter-spacing: -0.21px;
      color: ${({ theme }) => theme.accentColors.primary};
      margin-bottom: 4px;

      @media screen and (max-width: 420.99px) {
        font-size: 24px;
        line-height: 32px;
      }
    }

    & > div {
      height: 100%;
    }

    p {
      font-size: 14px;
      line-height: 17px;
      font-weight: 600;
      margin-bottom: 12px;
      margin-top: 4px;

      @media screen and (max-width: 767.99px) {
        margin-top: 10px;
      }
    }

    h3 {
      font-family: Inter;
      font-size: 33px;
      line-height: 44px;
      margin-bottom: 4px;

      @media screen and (max-width: 767.99px) {
        font-size: 24px;
        line-height: 30px;
      }

      @media screen and (max-width: 420.99px) {
        font-size: 20px;
        line-height: 38px;
      }
    }
  }

  .divider-wrapper {
    @media screen and (max-width: 630px) {
      display: none;
    }

    .divider {
      height: 52px;
      width: 0px;
      border: 1px solid #cacaca;
    }
  }
`;

interface HighestBidCountdownComboProps {
  highestBid: number | string;
  conversionRate: number;
  tokenMultiplicationFactor: number | string;
  tokenSymbol: string;
  startsAt: number;
  endsAt: number;
  currentAuctionStatus: number;
}

const HighestBidCountdownCombo = ({
  highestBid,
  conversionRate,
  tokenMultiplicationFactor,
  tokenSymbol,
  startsAt,
  endsAt,
  currentAuctionStatus,
}: HighestBidCountdownComboProps): JSX.Element => {
  const theme = useCurrentTheme();

  return (
    <HighestBidCountdownComboWrapper>
      <FlexRowWrapper>
        <FlexColumnWrapper className="highest-bid-wrapper">
          <p>Highest Bid</p>
          <h2>
            Ξ&nbsp;
            {new BigNumber(highestBid)
              .dividedBy(tokenMultiplicationFactor)
              .toFormat({
                decimalSeparator: '.',
                groupSeparator: ',',
                groupSize: 3,
              })}
            &nbsp;
            {tokenSymbol}
          </h2>
          <h4>
            $
            {new BigNumber(highestBid)
              .dividedBy(tokenMultiplicationFactor)
              .multipliedBy(conversionRate)
              .toFormat(2, {
                decimalSeparator: '.',
                groupSeparator: ',',
                groupSize: 3,
              })}
            &nbsp;USD
          </h4>
        </FlexColumnWrapper>
        <FlexColumnWrapper className="divider-wrapper">
          <div
            className="divider"
            style={{
              border:
                theme === 'light' ? '1px solid #E0E5E7' : '1px solid #E0E5E7',
            }}
          />
        </FlexColumnWrapper>
        <FlexColumnWrapper className="countdown-wrapper">
          {currentAuctionStatus !== 2 ? (
            <AuctionConditionalCountdown startsAt={startsAt} endsAt={endsAt} />
          ) : (
            <h2>Auction Ended</h2>
          )}
        </FlexColumnWrapper>
      </FlexRowWrapper>
    </HighestBidCountdownComboWrapper>
  );
};

export default HighestBidCountdownCombo;
