import styled from 'styled-components/macro';
import { ImFacebook2, ImTwitter } from 'react-icons/im';
import { SiInstagram, SiYoutube } from 'react-icons/si';

import Container from './Container';
import FlexColumnWrapper from 'components/common/FlexColumnWrapper';

import { ReactComponent as FirdaosLogo } from 'assets/logos/firdaos_logo.svg';

const FooterWrapper = styled.div`
  /* margin-top: 68px; */

  ${Container} {
    padding: 60px 15px;

    .grid {
      display: grid;
      grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr;
      grid-template-areas: ${FlexColumnWrapper} ${FlexColumnWrapper} ${FlexColumnWrapper} ${FlexColumnWrapper} ${FlexColumnWrapper};
      grid-gap: 20px;

      @media screen and (max-width: 998px) {
        grid-template-columns: 2fr 1fr 1fr 1fr;
        justify-content: center;
      }

      @media screen and (max-width: 767.99px) {
        grid-template-columns: 2fr 1fr 1fr;
      }

      @media screen and (max-width: 650px) {
        grid-template-columns: 2fr 1fr;
      }

      @media screen and (max-width: 575.99px) {
        grid-template-columns: 1fr;
      }

      ${FlexColumnWrapper} {
        width: 100%;

        svg {
          margin-bottom: 12px;
        }

        .description {
          max-width: 265px;
          font-family: Inter;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 28px;
          color: ${({ theme }) => theme.textColors.secondary};
          margin-bottom: 24px;
        }

        .footer-social-links {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-flow: row wrap;

          a {
            width: 40px;
            height: 40px;

            background: ${({ theme }) => theme.backgroundColors.secondary};
            border-radius: 50%;

            display: grid;
            place-items: center;

            margin-right: 18px;

            svg {
              color: ${({ theme }) => theme.textColors.secondary};
              margin-bottom: 0;
            }
          }
        }

        h4.column-heading {
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 23px;
          color: ${({ theme }) => theme.textColors.primary};
          margin-bottom: 32px;
        }

        & > a {
          font-family: Inter;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 19px;
          color: ${({ theme }) => theme.textColors.secondary};
          text-decoration: none;
          margin-bottom: 20px;

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
  }
`;

const Footer = () => {
  return (
    <FooterWrapper>
      <Container>
        <div className="grid">
          <FlexColumnWrapper>
            <FirdaosLogo />
            <p className="description">
              We also source appreciating real-estate assets from top developers
              around the Middle East and tokenise them in the form of NFTs on
              Firdaos to enable group-buying/pooling from collectors.
            </p>
            <section className="footer-social-links">
              <a
                href="http://google.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <ImFacebook2 />
              </a>
              <a
                href="http://google.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <ImTwitter />
              </a>
              <a
                href="http://google.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SiInstagram />
              </a>
              <a
                href="http://google.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SiYoutube />
              </a>
            </section>
          </FlexColumnWrapper>
          <FlexColumnWrapper>
            <h4 className="column-heading">Get Started</h4>
            <a
              href="http://google.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Resources
            </a>
            <a
              href="http://google.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Tutorials
            </a>
            <a
              href="http://google.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Examples
            </a>
            <a
              href="http://google.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Docs
            </a>
          </FlexColumnWrapper>
          <FlexColumnWrapper>
            <h4 className="column-heading">About</h4>
            <a
              href="http://google.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Stories
            </a>
            <a
              href="http://google.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Community
            </a>
            <a
              href="http://google.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Blog
            </a>
            <a
              href="http://google.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Brand Assets
            </a>
          </FlexColumnWrapper>
          <FlexColumnWrapper>
            <h4 className="column-heading">Follow Us</h4>
            <a
              href="http://google.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Facebook
            </a>
            <a
              href="http://google.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Twitter
            </a>
            <a
              href="http://google.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Instagram
            </a>
            <a
              href="http://google.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              List Item
            </a>
          </FlexColumnWrapper>
          <FlexColumnWrapper>
            <h4 className="column-heading">Follow Us</h4>
            <a
              href="http://google.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Facebook
            </a>
            <a
              href="http://google.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Twitter
            </a>
            <a
              href="http://google.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Instagram
            </a>
            <a
              href="http://google.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              List Item
            </a>
          </FlexColumnWrapper>
        </div>
      </Container>
    </FooterWrapper>
  );
};

export default Footer;
