import styled from 'styled-components/macro';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';

import { setWalletConnectedAsync } from 'actions/walletActions';

const NavConnectWalletButton = styled.button`
  border: none;
  outline: none;
  border-radius: 56px;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 170px;
  height: 35px;
  background: rgba(44, 141, 255, 0.1);

  z-index: 100;

  text-transform: capitalize;
  font-family: Inter;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;

  color: ${({ theme }) => theme.accentColors.primary};
  cursor: pointer;
`;

const NavConnectWallet = ({
  ...props
}: React.ButtonHTMLAttributes<HTMLButtonElement>): JSX.Element | null => {
  const { isWalletConnected } = useSelector((state: RootState) => state.wallet);

  const dispatch = useDispatch();

  return !isWalletConnected ? (
    <NavConnectWalletButton
      {...props}
      onClick={() => dispatch(setWalletConnectedAsync())}
    >
      Connect Wallet
    </NavConnectWalletButton>
  ) : null;
};

export default NavConnectWallet;
