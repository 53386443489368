import { axiosInstance } from './axiosInstance';

interface FetchExpiredAuctionsListProps {
  page: number;
  limit: number;
}

export const fetchExpiredAuctionsList = async ({
  page = 1,
  limit = 3,
}: FetchExpiredAuctionsListProps) => {
  const searchParams = new URLSearchParams();
  searchParams.append('page', page.toString());
  searchParams.append('limit', limit.toString());
  return axiosInstance.get(`/auction/list/expired?${searchParams.toString()}`);
};
