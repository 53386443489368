import { ethers } from 'ethers';

interface GetCurrentAuctionBidOwnerProps {
  auctionId: number;
  contract: ethers.Contract;
}

export const getCurrentAuctionBidOwner = async ({
  auctionId,
  contract,
}: GetCurrentAuctionBidOwnerProps) => {
  return contract.getCurrentBidOwner(auctionId);
};
