import styled from 'styled-components/macro';
import { GiTwoCoins } from 'react-icons/gi';
import { SiEthereum } from 'react-icons/si';

import FlexRowWrapper from 'components/common/FlexRowWrapper';

import { acceptedCryptoInfo } from 'utils/acceptedCryptoInfo';

const ReservePriceInfoWrapper = styled.div`
  width: auto;
  height: auto;

  ${FlexRowWrapper} {
    width: auto;
  }

  ${FlexRowWrapper} {
    align-items: center;
  }

  .circle {
    width: 45px;
    height: 45px;

    background: #e9dcff;
    border-radius: 50%;
    margin-right: 12px;

    display: grid;
    place-items: center;

    @media screen and (max-width: 575.99px) {
      width: 31px;
      height: 31px;
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .amount-text {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 25px;
    letter-spacing: -0.21px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.textColors.primary};
    margin-right: 13px;

    @media screen and (max-width: 575.99px) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .sub-text {
    font-family: Inter;
    font-weight: 600;
    font-size: 21px;
    line-height: 25px;
    letter-spacing: -0.21px;
    text-transform: capitalize;
    color: ${({ theme }) => theme.textColors.secondary};

    @media screen and (max-width: 575.99px) {
      font-size: 12px;
      line-height: 15px;
    }
  }
`;

interface ReservePriceInfoProps {
  isEthAuction: boolean;
  erc20TokenAddress: string;
  reservePrice: number;
}

const ReservePriceInfo = ({
  reservePrice,
  isEthAuction,
  erc20TokenAddress,
}: ReservePriceInfoProps): JSX.Element => {
  return (
    <ReservePriceInfoWrapper>
      <FlexRowWrapper>
        <div className="circle">
          {isEthAuction ? <SiEthereum /> : <GiTwoCoins />}
        </div>
        <p className="amount-text">
          Ξ&nbsp;{reservePrice}&nbsp;
          {acceptedCryptoInfo[`${erc20TokenAddress.toLowerCase()}`]?.symbol}
        </p>
        <p className="sub-text">reserve</p>
      </FlexRowWrapper>
    </ReservePriceInfoWrapper>
  );
};

export default ReservePriceInfo;
