import * as React from 'react';
import styled from 'styled-components/macro';

import AuctionCard from './AuctionCard';
import FlexRowWrapper from './common/FlexRowWrapper';
import SectionTitle from './common/SectionTitle';
import Spinner from './Spinner';

const AuctionCardsSectionWrapper = styled.section`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(362px, 1fr));
  grid-row-gap: 45px;
  position: relative;

  .spinner-row {
    grid-column: 1 / -1;
    justify-content: center;
    align-items: center;
  }

  .load-more-wrapper {
    margin-top: 15px;
    width: 100%;
    grid-column: 1 / -1;
    display: flex;
    justify-content: center;
    align-items: center;

    .load-more {
      width: 209px;
      height: 50px;
      left: 616px;
      top: 2727px;

      border: 1px solid ${({ theme }) => theme.textColors.primary};
      box-sizing: border-box;
      border-radius: 4px;

      display: grid;
      place-items: center;

      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      letter-spacing: -0.21px;

      color: ${({ theme }) => theme.textColors.primary};
      cursor: pointer;
    }
  }
`;

interface AuctionCardsSectionProps {
  sectionTitle: string;
  auctions: any[];
  isFetching?: boolean;
  showLoadMore?: boolean;
  onLoadMoreClick?: () => void;
  ref: any;
}

const AuctionCardsSection = React.forwardRef<
  HTMLDivElement,
  AuctionCardsSectionProps
>(
  (
    {
      sectionTitle = 'All NFTs',
      auctions = [],
      isFetching = false,
      showLoadMore = true,
      onLoadMoreClick = () => {},
    },
    ref
  ) => {
    return (
      <div ref={ref}>
        <SectionTitle title={sectionTitle} />
        <AuctionCardsSectionWrapper>
          {auctions?.length > 0 &&
            auctions.map((auction: any) => (
              <AuctionCard
                key={auction._id}
                idx={auction.auctionIndex}
                erc20TokenAddress={auction?.erc20TokenAddress}
                assetType={auction.nftDetails.assetMimetype}
                assetIpfsHash={auction.nftDetails.assetIpfsHash}
                title={auction.nftDetails.assetName}
                price={auction.startPrice}
                creator={auction.userAccountHandle}
                profileImage={auction.userProfileImage?.replace(
                  'ipfs.io',
                  'firdaos.mypinata.cloud'
                )}
                startsAt={auction.startTime}
                endsIn={auction.endsAt || auction.startTime + auction.duration}
              />
            ))}
          {isFetching ? (
            <FlexRowWrapper className="spinner-row">
              <Spinner />
            </FlexRowWrapper>
          ) : null}
          {showLoadMore ? (
            <div className="load-more-wrapper">
              <div className="load-more" onClick={onLoadMoreClick}>
                Load More
              </div>
            </div>
          ) : null}
        </AuctionCardsSectionWrapper>
      </div>
    );
  }
);

export default AuctionCardsSection;
