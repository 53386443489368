import { useState } from 'react';
import styled from 'styled-components/macro';

import { Theme } from 'hooks/useDarkMode';

const NewThemeToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer !important;

  .switch input {
    position: absolute;
    opacity: 0;
    cursor: pointer !important;
  }

  .switch {
    display: inline-block;
    font-size: 20px; /* 1 */
    height: 0.5em;
    width: 2em;
    background: #5cb1ff;
    border-radius: 1em;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .switch div {
    height: 1em;
    width: 1em;
    border-radius: 1em;
    background: #fff;
    box-shadow: 0 0.1em 0.3em rgba(0, 0, 0, 0.3);
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
    transition: all 300ms;
    cursor: pointer !important;
  }

  .switch input:checked + div {
    -webkit-transform: translate3d(100%, 0, 0);
    -moz-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    cursor: pointer !important;
    background: #2c8dff;
  }
`;

interface NewThemeToggleProps {
  toggleTheme: () => void;
}

const NewThemeToggle = ({ toggleTheme }: NewThemeToggleProps): JSX.Element => {
  const [isChecked, setIsChecked] = useState(
    localStorage.getItem('theme') === Theme.Dark
  );

  return (
    <NewThemeToggleWrapper>
      <label className="switch">
        <input
          type="checkbox"
          checked={isChecked}
          onChange={(e) => {
            e.stopPropagation();
            setIsChecked((prevState) => {
              toggleTheme();
              return !prevState;
            });
          }}
        />
        <div></div>
      </label>
    </NewThemeToggleWrapper>
  );
};

export default NewThemeToggle;
