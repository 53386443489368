import { useRef } from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { HiOutlineUser, HiOutlinePencil } from 'react-icons/hi';
import { FiPower } from 'react-icons/fi';
import { IoMoon } from 'react-icons/io5';

import { disconnectWallet } from 'actions/walletActions';
import useOutsideClick from 'hooks/useOutsideClick';

import FlexColumnWrapper from 'components/common/FlexColumnWrapper';
import NewThemeToggle from 'components/NewThemeToggle';

const NavProfileElementDropdownWrapper = styled.div`
  width: 182px;
  height: auto;
  padding: 10px 0;
  margin-top: 5px;
  position: absolute;
  display: block;
  right: 0;
  background: ${({ theme }) => theme.backgroundColors.primary};
  border: 1px solid ${({ theme }) => theme.borderColors.tertiary}77;
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.03);
  border-radius: 4px;
  z-index: 10;

  div {
    display: flex;
    justify-content: center;

    margin-bottom: 15px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  div > a,
  div > p {
    width: 100%;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;

    display: flex;
    align-items: center;

    color: ${({ theme }) => theme.textColors.secondary};
    text-decoration: none;

    svg {
      width: 16px;
      height: 16px;
      margin: 0 16px;
      color: ${({ theme }) => theme.textColors.secondary};
    }
  }

  .dark-mode-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;

    svg {
      margin-left: 0;
    }
  }
  div > p {
    width: auto;
  }

  .logout {
    width: 100%;
    min-height: 38px;

    display: grid;
    place-items: center;

    border-top: 1px solid rgba(150, 167, 180, 0.11);

    & > a {
      width: auto;
      margin-top: 6px;

      svg {
        margin-left: 0;
      }
    }
  }
`;

interface NavProfileElementDropdownProps {
  user:
    | {
        isFetchingProfile: boolean;
        profile: any;
        error: null;
      }
    | {
        error: any;
        isFetchingProfile: boolean;
        profile: null;
      };
  toggleTheme: () => void;
  onClose: () => void;
}

const NavProfileElementDropdown = ({
  user,
  toggleTheme,
  onClose,
}: NavProfileElementDropdownProps): JSX.Element => {
  const dispatch = useDispatch();

  const ref = useRef<HTMLDivElement>(null);

  useOutsideClick(ref, () => {
    onClose();
  });

  return (
    <NavProfileElementDropdownWrapper ref={ref}>
      <FlexColumnWrapper>
        {Boolean(user?.profile) && (
          <>
            <div>
              <Link to={`/profile/${user?.profile?.userAccountHandle}`}>
                <HiOutlineUser />
                My Profile
              </Link>
            </div>
            <div>
              <Link to={`/profile/${user?.profile?.userAccountHandle}/edit`}>
                <HiOutlinePencil />
                Edit Profile
              </Link>
            </div>
            <div className="dark-mode-toggle">
              <p>
                <IoMoon />
                Dark Mode
              </p>
              <NewThemeToggle toggleTheme={toggleTheme} />
            </div>
          </>
        )}
        <div
          className="logout"
          onClick={(e) => {
            e.stopPropagation();
            dispatch(disconnectWallet());
          }}
        >
          <Link to={`/`}>
            <FiPower />
            Logout
          </Link>
        </div>
      </FlexColumnWrapper>
    </NavProfileElementDropdownWrapper>
  );
};

export default NavProfileElementDropdown;
