import styled from 'styled-components/macro';
import Spinner from './Spinner';

const SpinnerLoaderWrapper = styled.div`
  width: 100%;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const SpinnerLoader = (): JSX.Element => {
  return (
    <SpinnerLoaderWrapper>
      <Spinner />
    </SpinnerLoaderWrapper>
  );
};

export default SpinnerLoader;
