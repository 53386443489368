import { useState, useEffect } from 'react';
import styled from 'styled-components/macro';

import FlexColumnWrapper from 'components/common/FlexColumnWrapper';
import FlexRowWrapper from 'components/common/FlexRowWrapper';

const AuctionCountDownWrapper = styled.div`
  h3 {
    font-family: 'Roboto Mono';
    font-weight: bold;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.21px;
    color: ${({ theme }) => theme.textColors.primary};
    margin-bottom: 22px;
  }

  ${FlexRowWrapper} {
    width: auto;

    ${FlexColumnWrapper} {
      width: auto;

      h4 {
        width: 100%;
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: ${({ theme }) => theme.textColors.tertiary};
      }
    }
  }
`;

const Panel = ({ n }: { n: number }) => <h3>{n < 10 ? '0' + n : n}</h3>;

interface AuctionCountDownProps {
  duration: number;
  onCountdownEnd: () => void;
}

const AuctionCountDown = ({
  duration,
  onCountdownEnd,
  ...props
}: AuctionCountDownProps &
  React.HTMLAttributes<HTMLDivElement>): JSX.Element => {
  const [secondsLeft, setSecondsLeft] = useState(duration);

  useEffect(() => {
    setSecondsLeft(duration);
  }, [duration]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (secondsLeft <= 0) {
        clearInterval(interval);
        onCountdownEnd();
      } else {
        setSecondsLeft((prevState) => prevState - 1);
      }
    }, 1000);

    return () => {
      Boolean(interval) && clearInterval(interval);
    };
  }, [duration, secondsLeft, onCountdownEnd]);

  const hours = Math.floor(secondsLeft / 3600);
  const minutesLeft = Math.floor(secondsLeft - hours * 3600);
  const minutes = Math.floor(minutesLeft / 60);
  const seconds = Math.floor(secondsLeft % 60);

  return (
    <AuctionCountDownWrapper {...props}>
      <FlexRowWrapper>
        <FlexColumnWrapper>
          <Panel n={hours} />
          <h4>Hours</h4>
        </FlexColumnWrapper>
        <h3>:</h3>
        <FlexColumnWrapper>
          <Panel n={minutes} />
          <h4>Minutes</h4>
        </FlexColumnWrapper>
        <h3>:</h3>
        <FlexColumnWrapper>
          <Panel n={seconds} />
          <h4>Seconds</h4>
        </FlexColumnWrapper>
      </FlexRowWrapper>
    </AuctionCountDownWrapper>
  );
};

export default AuctionCountDown;
