import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

import useAuctionBlockchainDetails from 'hooks/auction/useAuctionBlockchainDetails';

import FlexColumnWrapper from 'components/common/FlexColumnWrapper';
import FlexRowWrapper from 'components/common/FlexRowWrapper';
import AssetPreview from 'components/AssetPreview';
import NftTitle from 'components/auction/info/NftTitle';
import ProfilePill from 'components/ProfilePill';
import ReservePriceInfo from 'components/auction/info/ReservePriceInfo';
import HighestBidCountdownCombo from 'components/auction/HighestBidCountdownCombo';

import { acceptedCryptoInfo } from 'utils/acceptedCryptoInfo';

const RecommendedPostWrapper = styled.div`
  .main-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 60px;
    flex-flow: row wrap;

    @media screen and (max-width: 1199.99px) {
      justify-content: center;
    }

    .asset-column {
      width: 35%;
      min-width: 355px;

      @media screen and (max-width: 1028.99px) {
        margin-bottom: 42px;
      }
    }

    .info-column {
      width: 60%;
      min-width: 355px;
      margin-left: 32px;

      @media screen and (max-width: 1028.99px) {
        width: 95%;
        margin-left: 0;
      }
    }
  }

  .asset-column {
    .preview-wrapper {
      width: 100%;
      height: 511px;
      border-radius: 23px;

      display: grid;
      place-items: center;

      overflow: hidden;

      .wrapper {
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .info-column {
    & > ${FlexRowWrapper} {
      margin: 0 auto 42px auto;

      @media screen and (max-width: 575.99px) {
        width: 100%;
      }
    }
  }

  .row-1 {
    .nft-description {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 27px;
      letter-spacing: -0.21px;
      color: ${({ theme }) => theme.textColors.secondary};
    }
  }

  .row-2 {
    justify-content: space-between;
    flex-flow: row wrap;

    @media screen and (max-width: 575.99px) {
      & > div {
        margin: 12px auto;
      }
    }
  }

  .row-4 {
    justify-content: space-between;
    flex-wrap: wrap;

    @media screen and (max-width: 643.99px) {
      justify-content: center;
    }

    .place-bid-button {
      width: 441px;
      height: 58px;

      @media screen and (max-width: 643.99px) {
        width: 95%;
      }

      background: ${({ theme }) => theme.accentColors.primary};
      border-radius: 9px;
      text-decoration: none;

      display: flex;
      justify-content: center;
      align-items: center;

      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: -0.21px;

      color: #fff;
    }

    .view-art {
      text-decoration: none;
      width: 170px;
      height: 58px;

      border: 2px solid #536c6c;
      box-sizing: border-box;
      border-radius: 9px;

      display: grid;
      place-items: center;

      font-family: Inter;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      color: #536c6c;

      @media screen and (max-width: 643.99px) {
        margin-top: 20px;
        width: 95%;
      }
    }
  }
`;

interface RecommendedPostProps {
  auctionId: number;
  isEthAuction: boolean;
  erc20TokenAddress: string;
  nftTitle: string;
  nftDescription: string;
  ipfsHash: string;
  nftAssetMimetype: string;
  creatorProfileImageUrl: string;
  // creatorUsername: string;
  creatorAccountHandle: string;
  reservePrice: number;
  currentAuctionBid: string;
  conversionRate: { ethereum: { usd: number }; firdaos: { usd: number } };
  auctionStartTime: number;
  auctionEndTime: number;
}

const RecommendedPost = ({
  auctionId,
  isEthAuction,
  erc20TokenAddress,
  nftTitle,
  nftDescription,
  ipfsHash,
  nftAssetMimetype,
  creatorProfileImageUrl,
  // creatorUsername,
  creatorAccountHandle,
  reservePrice,
  currentAuctionBid,
  conversionRate,
  auctionStartTime,
  auctionEndTime,
}: RecommendedPostProps): JSX.Element => {
  const { currentAuctionStatus, currentBidAmount } =
    useAuctionBlockchainDetails({ auctionId, pollingInterval: 60000 });

  return (
    <RecommendedPostWrapper>
      <FlexRowWrapper className="main-row">
        <FlexColumnWrapper className="asset-column">
          <div className="preview-wrapper">
            <AssetPreview
              ipfsHash={ipfsHash}
              assetMimetype={nftAssetMimetype}
              hasControls={false}
            />
          </div>
        </FlexColumnWrapper>
        <FlexColumnWrapper className="info-column">
          <FlexRowWrapper className="row-1">
            <FlexColumnWrapper>
              <NftTitle
                nftTitle={
                  nftTitle?.length > 16
                    ? `${nftTitle.substr(0, 16)}...`
                    : nftTitle
                }
              />
              <p className="nft-description">
                {nftDescription?.length > 120
                  ? `${nftDescription.substr(0, 120)}...`
                  : nftDescription}
              </p>
            </FlexColumnWrapper>
          </FlexRowWrapper>
          <FlexRowWrapper className="row-2">
            <ProfilePill
              profileHandle={creatorAccountHandle}
              profileImage={creatorProfileImageUrl?.replace(
                'ipfs.io',
                'firdaos.mypinata.cloud'
              )}
            />
            <ReservePriceInfo
              isEthAuction={isEthAuction}
              reservePrice={reservePrice}
              erc20TokenAddress={erc20TokenAddress}
            />
          </FlexRowWrapper>
          <FlexRowWrapper className="row-3">
            <HighestBidCountdownCombo
              conversionRate={
                conversionRate?.[
                  acceptedCryptoInfo[`${erc20TokenAddress.toLowerCase()}`]
                    ?.coingeckoCryptoId
                ]?.usd
              }
              currentAuctionStatus={currentAuctionStatus}
              endsAt={auctionEndTime}
              highestBid={currentBidAmount ?? currentAuctionBid ?? '0'}
              startsAt={auctionStartTime}
              tokenMultiplicationFactor={
                acceptedCryptoInfo[`${erc20TokenAddress.toLowerCase()}`]
                  ?.multiplicationFactor
              }
              tokenSymbol={
                acceptedCryptoInfo[`${erc20TokenAddress.toLowerCase()}`]?.symbol
              }
            />
          </FlexRowWrapper>
          <FlexRowWrapper className="row-4">
            <Link className="place-bid-button" to={`/auction/${auctionId}`}>
              <p>Place a Bid</p>
            </Link>
            <a
              className="view-art"
              href={`https://firdaos.mypinata.cloud/ipfs/${ipfsHash}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              View Art
            </a>
          </FlexRowWrapper>
        </FlexColumnWrapper>
      </FlexRowWrapper>
    </RecommendedPostWrapper>
  );
};

export default RecommendedPost;
