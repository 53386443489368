import * as React from 'react';
import styled from 'styled-components/macro';

import useAuctionsListQuery from 'hooks/auction/useAuctionsListQuery';
import useConversionRateQuery from 'hooks/auction/useConversionRateQuery';
import useExpiredAuctionsListQuery from 'hooks/auction/useExpiredAuctionsListQuery';

import Container from 'components/layout/Container';
import AuctionCardsSection from 'components/AuctionCardsSection';
import Banner from 'components/Banner';
import RecommendedPostSection from 'components/landing/recommended/RecommendedPostSection';
import TopBanner from 'components/landing/TopBanner';
import FeaturedAuctionsSection from 'components/landing/featured/FeaturedAuctionsSection';
// import NewFooter from 'components/layout/NewFooter';
import Footer from 'components/layout/Footer';

const LandingPageWrapper = styled.div`
  min-height: calc(100vh - 50px);
  background: ${({ theme }) => theme.backgroundColors.primary};

  /* padding-bottom: 68px; */
`;

const LandingPage = () => {
  const allNftsSectionRef = React.useRef<HTMLDivElement>(null);

  const conversionRateQuery = useConversionRateQuery({
    coingeckoCryptoId: 'ethereum,tst,firdaos',
    coingeckoCurrency: 'usd',
  });

  const auctionsListQuery = useAuctionsListQuery({ limit: 6 });

  const expiredAuctionsListQuery = useExpiredAuctionsListQuery({ limit: 6 });

  return (
    <>
      <TopBanner
        onClick={() => {
          allNftsSectionRef?.current?.scrollIntoView();
        }}
      />
      <LandingPageWrapper>
        <Container>
          <RecommendedPostSection
            conversionRate={conversionRateQuery.data?.data}
          />
          <FeaturedAuctionsSection />
          <AuctionCardsSection
            ref={allNftsSectionRef}
            sectionTitle="All NFTS"
            auctions={
              auctionsListQuery?.data?.pages?.reduce((acc: any[], page) => {
                acc.push(...((page?.data?.docs as any[]) ?? []));
                return acc;
              }, []) || []
            }
            isFetching={
              auctionsListQuery.isFetching ||
              auctionsListQuery.isFetchingNextPage
            }
            showLoadMore={auctionsListQuery.hasNextPage}
            onLoadMoreClick={() => {
              return !auctionsListQuery.isFetching &&
                !auctionsListQuery.isFetchingNextPage
                ? auctionsListQuery.fetchNextPage()
                : '';
            }}
          />
          <AuctionCardsSection
            sectionTitle="Expired Auctions"
            auctions={
              expiredAuctionsListQuery?.data?.pages?.reduce(
                (acc: any[], page) => {
                  acc.push(...((page?.data?.docs as any[]) ?? []));
                  return acc;
                },
                []
              ) || []
            }
            showLoadMore={expiredAuctionsListQuery.hasNextPage}
            onLoadMoreClick={() => {
              return !expiredAuctionsListQuery.isFetching &&
                !expiredAuctionsListQuery.isFetchingNextPage
                ? expiredAuctionsListQuery.fetchNextPage()
                : '';
            }}
          />
        </Container>
        <Banner />
        {/* <NewFooter /> */}
      </LandingPageWrapper>
      <Footer />
    </>
  );
};

export default LandingPage;
