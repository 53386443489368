import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';

import { useCurrentTheme } from 'hooks/useCurrentTheme';

import FlexColumnWrapper from 'components/common/FlexColumnWrapper';
import FlexRowWrapper from 'components/common/FlexRowWrapper';
import AssetPreview from 'components/AssetPreview';
import ProfilePill from 'components/ProfilePill';
import ConditionalCountdown from 'components/ConditionalCountdown';

import numberWithCommas from 'utils/numberWithCommas';

const FeaturedAuctionCardWrapper = styled.div`
  width: 265px;
  min-height: 503px;

  background: ${({ theme }) => theme.backgroundColors.primary};
  box-shadow: 0px 1px 14px ${({ theme }) => theme.textColors.secondary}33;
  border-radius: 8px;

  margin: 12px auto;
  cursor: pointer;
  overflow: hidden;

  .nft-preview {
    width: 100%;
    height: 266px;

    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    .wrapper {
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .info {
    padding: 28px 20px;

    h3 {
      font-family: Clash Grotesk;
      font-style: normal;
      font-weight: 600;
      font-size: 26px;
      line-height: 32px;
      text-transform: capitalize;
      color: ${({ theme }) => theme.textColors.primary};
      margin-bottom: 21px;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .profile-pill {
      min-width: 153px;
      min-height: 37px;

      padding: 0 18px 0 7px;

      img,
      svg {
        margin-right: 15px;
      }

      .profile-handle {
        font-size: 13px;
        line-height: 16px;
      }
    }
  }

  .footer-section {
    width: 100%;
    position: relative;

    padding: 0 20px 20px 20px;
    border-radius: 0px 0px 15px 15px;

    align-items: center;
    justify-content: space-evenly;

    .divider {
      position: absolute;
      width: 100%;
      height: 0px;

      border: 1px solid ${({ theme }) => theme.textColors.tertiary}55;
      margin-bottom: 10px;
    }

    ${FlexColumnWrapper} {
      flex: 0 1 50%;

      p {
        font-family: Inter;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: -0.21px;
        color: ${({ theme }) => theme.textColors.tertiary};
        margin-bottom: 32px;
        text-transform: uppercase;
      }

      h3 {
        font-family: Inter;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.21px;
        color: ${({ theme }) => theme.textColors.primary};
      }
    }
  }
`;

interface FeaturedAuctionCardProps {
  auctionIndex: number;
  assetName: string;
  assetIpfsHash: string;
  assetMimetype: string;
  creatorName: string;
  creatorProfileImage: string;
  reservePrice: string;
  cryptoTokenSymbol: string;
  startsAt: number;
  endsAt: number;
}

const FeaturedAuctionCard = ({
  auctionIndex,
  assetName,
  assetIpfsHash,
  assetMimetype,
  creatorName,
  creatorProfileImage,
  reservePrice,
  cryptoTokenSymbol,
  startsAt,
  endsAt,
}: FeaturedAuctionCardProps): JSX.Element => {
  const history = useHistory();

  const theme = useCurrentTheme();

  return (
    <FeaturedAuctionCardWrapper
      onClick={() => history.push(`/auction/${auctionIndex}`)}
    >
      <FlexColumnWrapper>
        <div className="nft-preview">
          <AssetPreview
            assetMimetype={assetMimetype}
            ipfsHash={assetIpfsHash}
            hasControls={false}
          />
        </div>
        <div className="info">
          <h3>{assetName}</h3>
          <FlexRowWrapper>
            <ProfilePill
              profileHandle={creatorName}
              profileImage={creatorProfileImage?.replace(
                'ipfs.io',
                'firdaos.mypinata.cloud'
              )}
            />
          </FlexRowWrapper>
        </div>
        <FlexRowWrapper className="footer-section">
          <div className="divider"></div>
          <FlexColumnWrapper>
            <p style={{ color: theme === 'light' ? '#A5B2B9' : '#A5B2B9' }}>
              Reserve
            </p>
            <h3>
              Ξ&nbsp;{numberWithCommas(reservePrice)}&nbsp;
              {cryptoTokenSymbol}
            </h3>
          </FlexColumnWrapper>
          <FlexColumnWrapper>
            <ConditionalCountdown startsAt={startsAt} endsAt={endsAt} />
          </FlexColumnWrapper>
        </FlexRowWrapper>
      </FlexColumnWrapper>
    </FeaturedAuctionCardWrapper>
  );
};

export default FeaturedAuctionCard;
