import styled, { useTheme } from 'styled-components/macro';

import { Modal } from 'react-responsive-modal';

import config from 'config';

import IncorrectNetwork from 'assets/images/incorrect-network.png';

const IncorrectNetworkModalContentWrapper = styled.div`
  padding: 30px 48px;

  img {
    width: 48px;
    height: 48px;
    margin-bottom: 13px;
  }

  h3 {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 23px;
    color: #231e2d;
    margin-bottom: 13px;
  }

  p {
    width: 229px;

    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;

    color: #989da8;
  }
`;

interface IncorrectNetworkModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const IncorrectNetworkModal = ({
  isOpen,
  onClose,
}: IncorrectNetworkModalProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      center
      showCloseIcon={false}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      styles={{
        overlay: {
          backdropFilter: 'blur(5px)',
        },
        modalContainer: {
          background: 'rgba(255, 255, 255, 0.1)',
        },
        modal: {
          padding: 0,
          background: theme.backgroundColors.secondary,
          color: theme.textColors.primary,
          width: '80%',
          maxWidth: '364px',
          height: 250,
          borderRadius: '5px',
        },
        closeButton: {
          background: theme.backgroundColors.secondary,
          borderRadius: '50%',
        },
      }}
    >
      <IncorrectNetworkModalContentWrapper>
        <img src={IncorrectNetwork} alt="" />
        <h3>Wrong Network Selected</h3>
        <p>
          Hey looks like you chose the wrong network. Please switch back
          Ethereum's <strong>{config.eth.networkType}</strong> network
        </p>
      </IncorrectNetworkModalContentWrapper>
    </Modal>
  );
};

export default IncorrectNetworkModal;
