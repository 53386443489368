import styled from 'styled-components/macro';

import Container from 'components/layout/Container';
import FlexRowWrapper from 'components/common/FlexRowWrapper';
import FlexColumnWrapper from 'components/common/FlexColumnWrapper';

import TopBannerImage from 'assets/images/top-banner-image.png';

const TopBannerWrapper = styled.div`
  width: 100%;
  min-height: 421px;

  position: relative;

  background: #d2f7ff;
  backdrop-filter: blur(109px);

  margin: 0 auto 10px auto;

  display: flex;
  justify-content: center;
  flex-flow: column nowrap;
  padding: 0 64px;

  @media screen and (max-width: 575.99px) {
    padding: 0;
  }

  & > ${Container} {
    width: 100%;
  }

  & > ${Container} > ${FlexRowWrapper} {
    align-items: center;

    @media screen and (max-width: 768.99px) {
      flex-wrap: wrap;
      justify-content: center;
      padding: 48px 0 60px 0;
    }

    & > ${FlexColumnWrapper} {
      &:first-of-type {
        flex: 0 1 40%;

        @media screen and (max-width: 768.99px) {
          flex: 0 1 100%;
          order: 2;
          align-items: center;
        }

        h1 {
          max-width: 360px;
          font-family: Clash Grotesk;
          font-weight: 600;
          font-size: 36px;
          line-height: 44px;
          text-transform: capitalize;

          color: #101010;
          margin-bottom: 9px;

          @media screen and (max-width: 991.99px) {
            font-size: 32px;
            line-height: auto;
          }

          @media screen and (max-width: 768.99px) {
            text-align: center;
          }

          @media screen and (max-width: 575.99px) {
            width: 80%;
            height: auto;
            margin: 0 auto;
            text-align: center;
          }
        }

        p {
          max-width: 330px;
          font-family: Inter;
          font-weight: 600;
          font-size: 18px;
          line-height: 1.5;

          color: rgba(77, 44, 56, 0.55);
          margin-bottom: 28px;

          @media screen and (max-width: 991.99px) {
            font-size: 16px;
            line-height: auto;
          }

          @media screen and (max-width: 768.99px) {
            text-align: center;
            font-weight: normal;
          }
        }

        button.browse-now {
          width: 165px;
          height: 45px;

          background: #2c8dff;
          border-radius: 6px;
          outline: none;
          border: none;
          cursor: pointer;

          font-family: Inter;
          font-weight: 600;
          font-size: 18px;
          line-height: 22px;
          color: #ffffff;
        }
      }

      &:last-of-type {
        flex: 0 1 60%;

        img {
          width: 100%;
        }
      }
    }
  }
`;

interface TopBannerProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const TopBanner = ({ onClick }: TopBannerProps): JSX.Element => {
  return (
    <TopBannerWrapper>
      <Container>
        <FlexRowWrapper>
          <FlexColumnWrapper>
            <h1>NFT Marketplace for the middle east</h1>
            <p>
              Find the latest artworks from talented artists and your favourite
              celebrities
            </p>
            <button className="browse-now" onClick={onClick}>
              Browse Now
            </button>
          </FlexColumnWrapper>
          <FlexColumnWrapper>
            <img src={TopBannerImage} alt="" />
          </FlexColumnWrapper>
        </FlexRowWrapper>
      </Container>
    </TopBannerWrapper>
  );
};

export default TopBanner;
