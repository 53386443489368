import { useState, useEffect, useCallback, memo } from 'react';
import styled from 'styled-components/macro';
import AuctionCountDown from './AuctionCountDown';

const AuctionConditionalCountdownWrapper = styled.div`
  p {
    font-family: Inter;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: ${({ theme }) => theme.textColors.secondary};
    margin-bottom: 14px;
    text-transform: uppercase;
  }
`;

interface AuctionConditionalCountdownProps {
  startsAt: number;
  endsAt: number;
}

const AuctionConditionalCountdown = ({
  startsAt,
  endsAt,
  ...props
}: AuctionConditionalCountdownProps &
  React.HTMLAttributes<HTMLDivElement>): JSX.Element => {
  const [countdownType, setCountdownType] = useState<number>(0);
  const [duration, setDuration] = useState<number | null>(null);

  useEffect(() => {
    const now = Math.floor(new Date().getTime() / 1000);

    if (startsAt > now) {
      setCountdownType(1);
    } else if (endsAt > now) {
      setCountdownType(2);
    } else {
      setCountdownType(3);
    }
  }, [endsAt, startsAt]);

  useEffect(() => {
    const now = Math.floor(new Date().getTime() / 1000);
    if (countdownType === 1 && startsAt > now) {
      setDuration(startsAt - now);
    } else if (countdownType === 2 && endsAt > now) {
      setDuration(endsAt - now);
    } else {
      setDuration(0);
    }
  }, [startsAt, endsAt, countdownType]);

  const handleCountdownEnd = useCallback(() => {
    if ([1, 2].includes(countdownType)) {
      setDuration(null);

      return [1, 2].includes(countdownType)
        ? setCountdownType((prevState) => prevState + 1)
        : null;
    } else {
      setDuration(0);
    }
  }, [countdownType]);

  return (
    <AuctionConditionalCountdownWrapper {...props}>
      {!Boolean(duration) ? <p>Auction Over</p> : null}
      {duration !== null && duration > 0 && (
        <>
          {countdownType === 1 && <p>Starts In</p>}
          {countdownType === 2 && <p>Ends In</p>}
        </>
      )}
      {duration !== null && duration >= 0 && (
        <AuctionCountDown
          duration={duration}
          onCountdownEnd={handleCountdownEnd}
        />
      )}
    </AuctionConditionalCountdownWrapper>
  );
};

export default memo(AuctionConditionalCountdown);
