import { useState } from 'react';
import styled from 'styled-components/macro';
import { FaUserCircle } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';

import { useCurrentTheme } from 'hooks/useCurrentTheme';

const ProfilePillWrapper = styled.div`
  min-width: 173px;
  min-height: 43px;

  background: ${({ theme }) => theme.backgroundColors.primary};
  border: 1px solid ${({ theme }) => theme.textColors.tertiary}44;
  box-shadow: -1px 1px 5px ${({ theme }) => theme.textColors.tertiary}33;
  border-radius: 84px;

  padding: 0 25px 0 7px;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  cursor: pointer;

  img,
  svg {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    margin-right: 18px;
  }

  .profile-handle {
    font-family: Inter;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;

    /* color: ${({ theme }) => theme.textColors.primary}; */
  }
`;

interface ProfilePillProps {
  profileImage: string;
  profileHandle: string;
}

const ProfilePill = ({
  profileImage,
  profileHandle,
}: ProfilePillProps): JSX.Element => {
  const [showFallbackProfileIcon, setShowFallbackProfileIcon] = useState(false);

  const history = useHistory();

  const theme = useCurrentTheme();

  return (
    <ProfilePillWrapper
      className="profile-pill"
      onClick={(e) => {
        e.stopPropagation();
        history.push(`/profile/${profileHandle}`);
      }}
    >
      {profileImage && !showFallbackProfileIcon ? (
        <img
          src={profileImage?.replace('ipfs.io', 'firdaos.mypinata.cloud')}
          alt=""
          onError={() => setShowFallbackProfileIcon(true)}
        />
      ) : null}
      {!Boolean(profileImage) || showFallbackProfileIcon ? (
        <FaUserCircle />
      ) : null}
      <p
        className="profile-handle"
        style={{ color: theme === 'light' ? '#8EA2A8' : '#8EA2A8' }}
      >
        @{profileHandle}
      </p>
    </ProfilePillWrapper>
  );
};

export default ProfilePill;
