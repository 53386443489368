import styled from 'styled-components/macro';

const NftTitleWrapper = styled.div`
  width: 100%;

  @media screen and (max-width: 575.99px) {
    width: 95%;
    max-width: 95%;
  }

  h1 {
    width: 100%;
    font-family: Clash Grotesk;
    font-weight: 600;
    font-size: 36px;
    line-height: 64px;
    text-transform: capitalize;
    color: ${({ theme }) => theme.textColors.primary};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

interface NFTTitleProps {
  nftTitle: string;
}

const NftTitle = ({ nftTitle }: NFTTitleProps): JSX.Element => {
  return (
    <NftTitleWrapper>
      <h1>{nftTitle}</h1>
    </NftTitleWrapper>
  );
};

export default NftTitle;
