import { useState, useEffect } from 'react';

import auctionEngineContract from 'contract-interactions/instances/auctionEngineContract';
import { getAuctionStatus } from 'contract-interactions/getAuctionStatus';
import { getCurrentAuctionBidOwner } from 'contract-interactions/getCurrentAuctionBidOwner';
import { getCurrentAuctionBidsCount } from 'contract-interactions/getCurrentAuctionBidsCount';
import { getCurrentAuctionBidAmount } from 'contract-interactions/getCurrentAuctionBidAmount';

const useAuctionBlockchainDetails = ({
  auctionId,
  pollingInterval = 10000,
  shouldPoll = true,
}: {
  auctionId: number;
  pollingInterval?: number;
  shouldPoll?: boolean;
}) => {
  const [currentAuctionStatus, setCurrentAuctionStatus] = useState(0);
  const [currentBidOwner, setCurrentBidOwner] = useState<string | null>(null);
  const [currentBidCount, setCurrentBidCount] = useState(0);
  const [currentBidAmount, setCurrentBidAmount] = useState<string | null>(null);

  useEffect(() => {
    async function fetchBlockchainAuctionInfo() {
      try {
        if (typeof window.ethereum !== 'undefined') {
          const contract = auctionEngineContract();
          setCurrentAuctionStatus(
            await getAuctionStatus({
              auctionId,
              contract,
            })
          );
          setCurrentBidOwner(
            await getCurrentAuctionBidOwner({ auctionId, contract })
          );
          setCurrentBidCount(
            await getCurrentAuctionBidsCount({
              auctionId,
              contract,
            })
          );
          setCurrentBidAmount(
            await getCurrentAuctionBidAmount({
              auctionId,
              contract,
            })
          );
        }
      } catch (error) {
        console.log(error);
      }
    }

    if (Boolean(auctionId) || auctionId === 0) {
      fetchBlockchainAuctionInfo();
    }

    let interval: NodeJS.Timeout;
    if (
      (Boolean(auctionId) || auctionId === 0) &&
      currentAuctionStatus === 1 &&
      shouldPoll
    ) {
      interval = setInterval(fetchBlockchainAuctionInfo, pollingInterval);
    }

    return () => {
      Boolean(interval) && clearInterval(interval);
    };
  }, [auctionId, currentAuctionStatus, pollingInterval, shouldPoll]);

  return {
    currentAuctionStatus,
    currentBidOwner,
    currentBidCount,
    currentBidAmount,
  };
};

export default useAuctionBlockchainDetails;
