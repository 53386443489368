import styled from 'styled-components/macro';

import useRecommendedAuctionQuery from 'hooks/auction/useRecommendedAuctionQuery';

import RecommendedPost from './RecommendedPost';

const RecommendedPostSectionWrapper = styled.section``;

interface RecommendedPostSectionProps {
  conversionRate: { ethereum: { usd: number }; firdaos: { usd: number } };
}

const RecommendedPostSection = ({
  conversionRate,
}: RecommendedPostSectionProps): JSX.Element => {
  const { data: auctionDetails } = useRecommendedAuctionQuery();

  return (
    <RecommendedPostSectionWrapper>
      {auctionDetails?.data && auctionDetails?.data?.length !== 0 ? (
        <RecommendedPost
          auctionId={auctionDetails.data.auctionIndex}
          isEthAuction={auctionDetails.data.isEthAuction}
          erc20TokenAddress={auctionDetails.data.erc20TokenAddress}
          nftTitle={auctionDetails.data.nftDetails.assetName}
          nftDescription={auctionDetails.data.nftDetails.assetDescription}
          ipfsHash={auctionDetails.data.nftDetails.assetIpfsHash}
          nftAssetMimetype={auctionDetails.data.nftDetails.assetMimetype}
          creatorProfileImageUrl={auctionDetails.data.userProfileImage?.replace(
            'ipfs.io',
            'firdaos.mypinata.cloud'
          )}
          // creatorUsername={auctionDetails.data.username}
          creatorAccountHandle={auctionDetails.data.userAccountHandle}
          currentAuctionBid={auctionDetails.data.latestBidAmount}
          reservePrice={auctionDetails.data.startPrice}
          conversionRate={conversionRate}
          auctionStartTime={auctionDetails.data.startTime}
          auctionEndTime={auctionDetails.data.endsAt}
        />
      ) : null}
    </RecommendedPostSectionWrapper>
  );
};

export default RecommendedPostSection;
