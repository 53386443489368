import { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { Link, NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import { FaTelegramPlane } from 'react-icons/fa';
// import { SiTwitter, SiInstagram } from 'react-icons/si';
import { GiHamburgerMenu } from 'react-icons/gi';
import { AiFillPlusCircle } from 'react-icons/ai';

import { RootState } from 'store';

import Container from './Container';
import FlexRowWrapper from 'components/common/FlexRowWrapper';
import NavConnectWallet from 'components/navbar/NavConnectWallet';
import NavProfileElement from 'components/navbar/NavProfileElement';
import NavSlider from './NavSlider';

import { ReactComponent as FirdaosLogo } from 'assets/logos/firdaos_logo.svg';

const NavbarWrapper = styled.nav<{ showBackgroundBlur: boolean }>`
  width: 100%;
  height: 52px;
  left: 0px;
  right: 0;
  top: 0px;
  z-index: 150;

  /* background: ${({ theme }) => theme.backgroundColors.primary}; */
  /* border-bottom: 2px solid ${({ theme }) => theme.borderColors.tertiary}; */

  position: sticky;

  backdrop-filter: ${({ showBackgroundBlur }) =>
    showBackgroundBlur
      ? 'blur(12px) saturate(100%)'
      : 'blur(0px) saturate(100%)'};
  background-color: ${({ showBackgroundBlur }) =>
    showBackgroundBlur ? 'rgba(245, 244, 241, 0.25)' : 'transparent'};
  transition: ${({ showBackgroundBlur }) =>
    showBackgroundBlur
      ? 'background-color 0.5s ease'
      : 'background-color 4s ease'};

  ${Container} {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > ${FlexRowWrapper}:nth-of-type(1) {
      width: auto;
      align-items: center;

      a {
        display: flex;
        align-items: center;
      }

      svg {
        width: 114px;
        height: 18px;
        margin-right: 38px;
        margin-left: 20px;
        color: ${({ theme }) => theme.textColors.primary};

        @media screen and (max-width: 767.99px) {
          margin: 0 auto;
        }
      }

      @media screen and (max-width: 767.99px) {
        padding: 0 24px;
      }
    }

    & > ${FlexRowWrapper}:nth-of-type(2) {
      width: auto;
      align-items: center;
    }

    ul {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-flow: row wrap;

      @media screen and (max-width: 767.99px) {
        display: none;
      }

      li {
        list-style: none;
        margin-right: 32px;

        &:last-of-type {
          margin-right: 0;
        }

        a {
          display: flex;
          justify-content: center;
          align-items: center;
          text-decoration: none;

          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;

          text-transform: uppercase;
          color: ${({ theme }) => theme.textColors.secondary};
        }

        a.active {
          color: ${({ theme }) => theme.textColors.primary};
        }
      }
    }
  }

  .connect-wallet-button {
    @media screen and (max-width: 767.99px) {
      display: none;
    }
  }

  .menu-icon {
    display: none;
    position: absolute;
    right: 32px;
    color: ${({ theme }) => theme.textColors.primary} !important;
    width: 24px;
    height: 24px;
    cursor: pointer;

    @media screen and (max-width: 767.99px) {
      display: block;
    }
  }

  .create-nft {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-transform: capitalize;
    color: ${({ theme }) => theme.accentColors.primary};
    text-decoration: none;
    margin-right: 12px;

    display: flex;
    align-items: center;

    svg {
      color: ${({ theme }) => theme.accentColors.primary};
      margin-right: 4px;
    }

    @media screen and (max-width: 767.99px) {
      display: none;
    }
  }

  /* .social-links-row {
    width: auto;
    align-items: center;

    @media screen and (max-width: 899.99px) {
      display: none;
    }

    a {
      display: grid;
      place-items: center;
      margin-right: 18px;

      &:last-of-type {
        margin-right: 0;
      }

      svg {
        width: 16px;
        height: 16px;
        color: ${({ theme }) => theme.textColors.secondary};
      }
    }
  } */

  .profile-element-wrapper {
    @media screen and (max-width: 767.99px) {
      display: none;
    }
  }
`;

interface NavbarProps {
  toggleTheme: () => void;
}

const Navbar = ({ toggleTheme }: NavbarProps): JSX.Element => {
  const [sliderOpen, setSliderOpen] = useState(false);
  const [showBackgroundBlur, setShowBackgroundBlur] = useState(false);

  const { isWalletConnected } = useSelector((state: RootState) => state.wallet);
  const user = useSelector((state: RootState) => state.user);

  useEffect(() => {
    const handleWindowScroll = () => {
      if (window.pageYOffset > 0) {
        setShowBackgroundBlur(true);
      } else {
        setShowBackgroundBlur(false);
      }
    };

    window.addEventListener('scroll', handleWindowScroll);

    return () => window.removeEventListener('scroll', handleWindowScroll);
  }, []);

  return (
    <>
      <NavbarWrapper showBackgroundBlur={showBackgroundBlur}>
        <Container>
          <FlexRowWrapper>
            <Link to="/">
              <FirdaosLogo />
            </Link>
            <ul>
              <li>
                <NavLink exact to="/">
                  Home
                </NavLink>
              </li>
              <li>
                <a href="https://firdaos.com/creators/">Creators</a>
              </li>
              <li>
                <a href="https://firdaos.com/#about">About Us</a>
              </li>
            </ul>
            <GiHamburgerMenu
              className="menu-icon"
              onClick={() => setSliderOpen((prevState) => !prevState)}
            />
          </FlexRowWrapper>
          {/* <FlexRowWrapper className="social-links-row">
          <a href="https://t.me/FirdaosOfficial">
            <FaTelegramPlane />
          </a>
          <a href="https://twitter.com/FirdaosOfficial">
            <SiTwitter />
          </a>
          <a href="https://www.instagram.com/sahilsays/">
            <SiInstagram />
          </a>
        </FlexRowWrapper> */}
          <FlexRowWrapper style={{ width: 'auto' }}>
            {Boolean(user?.profile) && isWalletConnected && (
              <NavLink className="create-nft" to="/nft/create">
                <AiFillPlusCircle />
                Create NFT
              </NavLink>
            )}
            <NavConnectWallet className="connect-wallet-button" />
            <div className="profile-element-wrapper">
              <NavProfileElement toggleTheme={toggleTheme} />
            </div>
          </FlexRowWrapper>
        </Container>
      </NavbarWrapper>
      <NavSlider
        isOpen={sliderOpen}
        onClose={() => setSliderOpen(false)}
        toggleTheme={toggleTheme}
      />
    </>
  );
};

export default Navbar;
