import TstErc20Token from 'artifacts/TST_ERC20_Token.json';
import FdoErc20Token from 'artifacts/FDO_ERC20_Token.json';

import tstErc20TokenContract from 'contract-interactions/instances/tstErc20TokenContract';
import fdoErc20TokenContract from 'contract-interactions/instances/fdoErc20TokenContract';

const ropstenAcceptedCryptoInfo = {
  '0x0000000000000000000000000000000000000000': {
    symbol: 'ETH',
    decimalPlaces: 18,
    multiplicationFactor: '1e+18',
    abi: [],
    contract: null,
    coingeckoCryptoId: 'ethereum',
  },
  '0x722dd3f80bac40c951b51bdd28dd19d435762180': {
    symbol: 'TST',
    decimalPlaces: 18,
    multiplicationFactor: '1e+18',
    abi: TstErc20Token.abi,
    contract: tstErc20TokenContract,
    coingeckoCryptoId: 'touch-social',
  },
  '0x2f0bd73169747a04df5e74a4d2de64ae2b4a91cb': {
    symbol: 'FDO',
    decimalPlaces: 18,
    multiplicationFactor: '1e+18',
    abi: FdoErc20Token.abi,
    contract: fdoErc20TokenContract,
    coingeckoCryptoId: 'firdaos',
  },
};

const mainnetAcceptedCryptoInfo = {
  '0x0000000000000000000000000000000000000000': {
    symbol: 'ETH',
    decimalPlaces: 18,
    multiplicationFactor: '1e+18',
    abi: [],
    contract: null,
    coingeckoCryptoId: 'ethereum',
  },
  '0x361887c1d1b73557018c47c8001711168128cf69': {
    symbol: 'FDO',
    decimalPlaces: 18,
    multiplicationFactor: '1e+18',
    abi: FdoErc20Token.abi,
    contract: fdoErc20TokenContract,
    coingeckoCryptoId: 'firdaos',
  },
};

export const acceptedCryptoInfo = (function () {
  switch (process.env.REACT_APP_ENV) {
    case 'production':
      return mainnetAcceptedCryptoInfo;

    case 'staging':
      return ropstenAcceptedCryptoInfo;

    case 'local':
    default:
      return ropstenAcceptedCryptoInfo;
  }
})();
