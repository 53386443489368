import axios from 'axios';

export const fetchCryptoToCurrencyConversionRate = ({
  coingeckoCryptoId,
  coingeckoCurrency,
}) => {
  return axios.get(
    `https://api.coingecko.com/api/v3/simple/price?ids=${coingeckoCryptoId}&vs_currencies=${coingeckoCurrency}`
  );
};
