import { BigNumber, ethers } from 'ethers';

interface GetCurrentAuctionBidsCountProps {
  auctionId: number;
  contract: ethers.Contract;
}

export const getCurrentAuctionBidsCount = async ({
  auctionId,
  contract,
}: GetCurrentAuctionBidsCountProps): Promise<number> => {
  return contract
    .getBidCount(auctionId)
    .then((resp: BigNumber) => resp.toNumber());
};
