import {
  SET_WALLET_CONNECTING,
  SET_WALLET_CONNECTED,
  DISCONNECT_WALLET,
  SHOW_CONNECT_WALLET_MODAL,
  HIDE_CONNECT_WALLET_MODAL,
} from '../actions/walletActions';

const initialState = {
  isWalletConnecting: false,
  isWalletConnected: false,
  accountAddress: '',
  showConnectWalletModal: false,
};

const connectWalletReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_WALLET_CONNECTING:
      return {
        ...state,
        isWalletConnecting: true,
        isWalletConnected: false,
        accountAddress: '',
        showConnectWalletModal: false,
      };

    case SET_WALLET_CONNECTED:
      return {
        ...state,
        isWalletConnecting: false,
        isWalletConnected: true,
        accountAddress: action.payload.accountAddress,
        showConnectWalletModal: false,
      };

    case DISCONNECT_WALLET:
      return { ...initialState };

    case SHOW_CONNECT_WALLET_MODAL:
      return {
        ...state,
        showConnectWalletModal: true,
      };

    case HIDE_CONNECT_WALLET_MODAL:
      return {
        ...state,
        showConnectWalletModal: false,
      };

    default:
      return state;
  }
};

export default connectWalletReducer;
