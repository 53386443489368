import styled from 'styled-components/macro';

const Container = styled.div`
  /* max-width: 1230px; */
  max-width: 1120px;
  width: auto;
  height: auto;

  margin: 0 auto;
  position: relative;
`;

export default Container;
