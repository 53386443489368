import { AppConfig } from 'types/app-config';
import erc20TokenOptions from './utils/erc20TokenOptions';

const prodConfig: AppConfig = {
  apiBaseUrl: 'https://firdaos.prod.ocg.technology',
  erc20TokenOptions,
  etherscan: {
    transactionBaseUrl: 'https://etherscan.io/tx/',
  },
  eth: {
    auctionEngineAddress: '0x38A8E22B31001a63096b07Bf0ADA8Be1D879b4fd',
    collectionAddress: '0xb5203eddc40aA3C237e9bc2Dc39e979cf63f1115',
    fdoErc20TokenAddress: '0x361887c1d1b73557018c47c8001711168128cf69',
    networkType: 'mainnet',
  },
};

const stagingConfig: AppConfig = {
  apiBaseUrl: 'https://fan-eth-stg.ocg.technology',
  erc20TokenOptions,
  etherscan: {
    transactionBaseUrl: 'https://ropsten.etherscan.io/tx/',
  },
  eth: {
    auctionEngineAddress: '0xa60C3cd809BE393Adf2199392e3D766b323b723B',
    collectionAddress: '0x928E2D80d2EBD8B457Ec2565e5d11da8491dE409',
    fdoErc20TokenAddress: '0x2F0Bd73169747a04df5E74A4d2De64ae2B4A91cb',
    networkType: 'ropsten',
  },
};

const localConfig: AppConfig = {
  apiBaseUrl: 'http://localhost:8000',
  erc20TokenOptions,
  etherscan: {
    transactionBaseUrl: 'https://ropsten.etherscan.io/tx/',
  },
  eth: {
    auctionEngineAddress: '0xa60C3cd809BE393Adf2199392e3D766b323b723B',
    collectionAddress: '0x928E2D80d2EBD8B457Ec2565e5d11da8491dE409',
    fdoErc20TokenAddress: '0x2F0Bd73169747a04df5E74A4d2De64ae2B4A91cb',
    networkType: 'ropsten',
  },
};

export default (function () {
  switch (process.env.REACT_APP_ENV) {
    case 'production':
      return prodConfig;

    case 'staging':
      return stagingConfig;

    case 'local':
    default:
      return localConfig;
  }
})();
