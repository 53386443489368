import styled from 'styled-components/macro';

import FlexColumnWrapper from 'components/common/FlexColumnWrapper';
import FlexRowWrapper from 'components/common/FlexRowWrapper';

const BannerWrapper = styled.div`
  width: 100%;
  height: 356px;
  background: linear-gradient(
    270.49deg,
    #2cd9ff -5.11%,
    #ffa4a4 27.83%,
    #f68eff 67.46%,
    #2c8dff 102.07%
  );
  backdrop-filter: blur(249px);

  margin-top: 60px;

  ${FlexColumnWrapper} {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;

    h2 {
      width: 540px;
      font-family: Clash Grotesk;
      font-style: normal;
      font-weight: 600;
      font-size: 48px;
      line-height: 59px;
      text-align: center;
      margin-bottom: 49px;
      z-index: 100;
      color: #fff;

      @media screen and (max-width: 575.99px) {
        width: 90%;
        font-size: 46px;
        line-height: 57px;
        text-align: center;
      }
    }

    ${FlexRowWrapper} {
      justify-content: center;
      align-items: center;
      z-index: 100;

      .get-started-today {
        width: 209px;
        height: 50px;

        background: #ffffff;
        border-radius: 8px;

        display: grid;
        place-items: center;

        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 17px;
        line-height: 21px;
        text-transform: capitalize;

        color: rgba(38, 48, 83, 0.72);
        cursor: pointer;
      }
    }
  }
`;

const Banner = (): JSX.Element => {
  return (
    <BannerWrapper>
      <FlexColumnWrapper>
        <h2>Join As A Creator</h2>
        <FlexRowWrapper>
          <div className="get-started-today">Get Started Today</div>
        </FlexRowWrapper>
      </FlexColumnWrapper>
    </BannerWrapper>
  );
};

export default Banner;
