import { BigNumber, ethers } from 'ethers';

interface GetCurrentAuctionBidAmountProps {
  auctionId: number;
  contract: ethers.Contract;
}

export const getCurrentAuctionBidAmount = async ({
  auctionId,
  contract,
}: GetCurrentAuctionBidAmountProps): Promise<string> => {
  return contract
    .getCurrentBidAmount(auctionId)
    .then((resp: BigNumber) => resp.toString());
};
