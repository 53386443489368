import requestMetaMaskAccounts from 'utils/metamask/requestMetaMaskAccounts';

export const SET_WALLET_CONNECTING = 'SET_WALLET_CONNECTING';
export const SET_WALLET_CONNECTED = 'SET_WALLET_CONNECTED';
export const DISCONNECT_WALLET = 'DISCONNECT_WALLET';
export const SHOW_CONNECT_WALLET_MODAL = 'SHOW_CONNECT_WALLET_MODAL';
export const HIDE_CONNECT_WALLET_MODAL = 'HIDE_CONNECT_WALLET_MODAL';

export const setWalletConnecting = () => ({
  type: SET_WALLET_CONNECTING,
});

export const setWalletConnected = (payload) => ({
  type: SET_WALLET_CONNECTED,
  payload,
});

export const disconnectWallet = () => {
  localStorage.clear();
  return {
    type: DISCONNECT_WALLET,
  };
};

export const showConnectWalletModal = () => ({
  type: SHOW_CONNECT_WALLET_MODAL,
});

export const hideConnectWalletModal = () => ({
  type: HIDE_CONNECT_WALLET_MODAL,
});

export const setWalletConnectedAsync = () => async (dispatch) => {
  if (typeof window.ethereum === 'undefined')
    return dispatch(showConnectWalletModal());

  dispatch(setWalletConnecting());
  const metamaskAccounts = await requestMetaMaskAccounts();
  localStorage.setItem('isWalletConnected', 'true');
  localStorage.setItem('accountAddress', metamaskAccounts[0]);
  return dispatch(setWalletConnected({ accountAddress: metamaskAccounts[0] }));
};
