import styled from 'styled-components/macro';

import { useCurrentTheme } from 'hooks/useCurrentTheme';

import FlexColumnWrapper from './FlexColumnWrapper';
import FlexRowWrapper from './FlexRowWrapper';

const SectionTitleWrapper = styled(FlexColumnWrapper)`
  padding: 45px 10px 0 10px;
  margin-bottom: 52px;

  ${FlexRowWrapper} {
    margin-bottom: 10px;

    .section-title {
      width: auto;
      font-family: Clash Grotesk;
      font-weight: bold;
      font-size: 32px;
      line-height: 46px;
      text-transform: uppercase;
      white-space: nowrap;
      color: ${({ theme }) => theme.textColors.tertiary};
    }
  }

  .line {
    width: 100%;
    height: 0px;
    /* border: 1px solid ${({ theme }) => theme.textColors.tertiary}; */
  }
`;

const SectionTitle = ({ title }: { title: string }): JSX.Element => {
  const theme = useCurrentTheme();

  return (
    <SectionTitleWrapper>
      <FlexRowWrapper>
        <h2 className="section-title">{title}</h2>
      </FlexRowWrapper>
      <div
        className="line"
        style={{
          border: theme === 'light' ? '1px solid #E2E4E7' : '1px solid #E2E4E7',
        }}
      />
    </SectionTitleWrapper>
  );
};

export default SectionTitle;
