import { ethers } from 'ethers';

import { ethersSigner } from 'utils/ethers/signer';
import TstErc20Token from 'artifacts/TST_ERC20_Token.json';

const tstErc20TokenContract = () => {
  const signer = ethersSigner();
  const contract = new ethers.Contract(
    '0x722dd3f80bac40c951b51bdd28dd19d435762180',
    TstErc20Token.abi,
    signer
  );
  return contract;
};

export default tstErc20TokenContract;
